<template>
	<div class="home-container">
		<van-nav-bar title="在线充值" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#ddb285" @click="back()" />
			</template>
			<template #right>
				<span class="nav-right" @click="$router.push({path:'/RechargeRecord'})">充值记录</span>
			</template>
		</van-nav-bar>
		<div class="home-scroll">
			<div class="noticeContent">
				<div class="navs" style="margin-top: -14px;">
					<nav
						style="flex-wrap: wrap; justify-content: center;height: 44px; padding-top: 2vw;display: flex;flex-wrap: wrap;">
						<a :class="{ 'active': active == 0 }" @click="active = 0">TRC充值</a>
						<a :class="{ 'active': active == 1 }" @click="active = 1">ERC充值</a>
						<a :class="{ 'active': active == 2 }" @click="active = 2">人工充值</a>
					</nav>
				</div>
				<div class="item" style="margin-top: 50px;" v-if="active == 0">
					<ul>
						<div class="main">
							<div class="withdrawMoney" style="font-size: 16px;">
								<div class="erwmpic">
									<img class="erwmpic_wh" :src="this.usdtimg"><br>
									<span style="color: #fff; font-size: 16px;">USDT充值地址</span>
									<div style="font-size: 14px;">
										<span style="color: #fff;">{{this.usdtadder}}</span>
										<button class="fuzhi" style="margin-left: 10px;" @click="copytxt">复制</button>
									</div>
								</div>
							</div>
							<!-- <div style="background-color: #282828; padding: 1px 2.667vw; color: #ddb285;">付款信息</div> -->
							<!-- 充值金额 -->
							<div class="withdrawMoney1" style="margin-top: 22px;">
								<span class="xinxi" style="font-size: 16px;">充值金额：</span>
								<input style="font-size: 16px;;"  class="shuru" v-model="withdraw_money" type="number">
								<!-- <div style="font-size: 15px; margin-top: 9px;"></div> -->
							</div>
							<!-- 钱包地址 -->
							<div class="withdrawMoney1" style="font-size: 16px; margin-top: 12px;">
								<span class="xinxi" style="font-size: 16px;">钱包地址：</span>
								<input class="shuru" v-model="qadder" type="text" />
							</div>
							<!-- 充值截图 -->
							<div style="font-size: 16px; margin-top: 12px;" class="withdrawMoney1" >
								<span class="xinxi" style="font-size: 16px;">充值截图：</span>
								<input type="file" @change="handleFileChange" style="display: none; font-size: 16px;" ref="fileInput"
									accept="image/*">
								<img v-if="isshow" style="height: 60px; width: 60px;"
									src="../../../public/img/img.png" alt="" @click="addpic" />
								<img :src="mpic" style="height: 80px; width: 80px;" v-if="ispic" />
							</div>
							<!-- 联系方式 -->
							<div style="margin-top: 1vw; font-size: 16px; margin-top: 12px;" class="withdrawMoney1" >
								<span class="xinxi" style="font-size: 16px;">联系方式：</span>
								<input class="shuru" v-model="tel" type="text" />
							</div>
							<van-button style="margin-top: 20px; width: 92%;" class="withdraw_btn" type="default"
								@click="doWithdraw()">点击充值</van-button>
						</div>
					</ul>
				</div>
				<div class="item" style="margin-top: 50px;" v-if="active == 1">
					<ul>
						<div class="main">
							<div class="withdrawMoney">
								<div class="erwmpic">
									<img class="erwmpic_wh" :src="this.erwmpic"><br>
									<span style="color: #fff;">ERC充值地址</span>
									<div style="font-size: 14px;">
										<span style="color: #fff;">{{this.ercadder}}</span>
										<button class="fuzhi" style="margin-left: 10px;" @click="copytxt2">复制</button>
									</div>
								</div>
							</div>
							<!-- <div style="background-color: #282828; padding: 1px 2.667vw; color: #ddb285;">付款信息</div> -->
							<!-- 充值金额 -->
							<div class="withdrawMoney1"  style="font-size: 16px; margin-top: 22px;">
								<span class="xinxi" style="font-size: 16px;">充值金额：</span>
								<input style="font-size: 16px;" class="shuru" v-model="withdraw_money" type="number">
								<!-- <div style="font-size: 15px; margin-top: 9px;">($)</div> -->
							</div>
							<!-- 钱包地址 -->
							<div class="withdrawMoney1" style="font-size: 16px; margin-top: 12px;">
								<span class="xinxi" style="font-size: 16px;">钱包地址：</span>
								<input style="font-size: 16px;" class="shuru" v-model="qadder" type="text" />
							</div>
							<!-- 充值截图 -->
							<div class="withdrawMoney1" style="font-size: 16px; margin-top: 12px;">
								<span class="xinxi" style="font-size: 16px;">充值截图：</span>
								<input  type="file" @change="handleFileChange" style="display: none; font-size: 16px;" ref="fileInput"
									accept="image/*">
								<img v-if="isshow" style="height: 60px; width: 60px;"
									src="../../../public/img/img.png" alt="" @click="addpic" />
								<img :src="mpic" style="height: 80px; width: 80px;" v-if="ispic" />
							</div>
							<!-- 联系方式 -->
							<div style="margin-top: 1vw; font-size: 16px; margin-top: 12px;
							" class="withdrawMoney1">
								<span class="xinxi" style="font-size: 16px;">联系方式：</span>
								<input style="font-size: 16px;" class="shuru" v-model="tel" type="text" />
							</div>
							<van-button style="margin-top: 20px; width: 92%;" class="withdraw_btn" type="default"
								@click="doWithdraw2()">点击充值</van-button>
						</div>
					</ul>
				</div>
				<div v-if="active == 2" style="height:auto; margin-top: 50px;">
					<div class="item">
						<ul>
							<div class="servicelistItem">
								<div class="servicelistItemTop">
									<img class="servicelistItemImage" src="img/mine/kefu.png">
									<div class="servicelistItemText">
										{{this.$store.getters.getBaseInfo.name !==undefined ?this.$store.getters.getBaseInfo.name:'Private Sex'}}
									</div>
									<div class="servicelistItemBtn" @click="toServicePage()">
										<div class="servicelistItemBtnText">联系</div>
									</div>
								</div>
								<div class="servicelistItemBottom">
									<div class="servicelistItemText">
										全天7 * 24小时竭诚为您服务
									</div>
								</div>

							</div>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				active: 0,
				showPopover: false,
				withdraw_money: "",
				userInfo: {},
				withdrawRole: {},
				systeminfo: [],
				usdtimg:'',
				erwmpic: "",
				tel: '',
				qadder: '',
				bigpic: '',
				isshow: true,
				ispic: false,
				selectedImage: null,
				mpic: '',
				usdtadder: "",
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			toServicePage() {
				this.$router.push("ServicePage");
			},

			copytxt() {
				let input = document.createElement('input'); // 构建临时input
				input.value = this.usdtadder; // 要复制的内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand('copy') // 选择实例内容
				document.body.removeChild(input); // 删除临时实例
				this.$toast('地址复制成功');
			},
			copytxt2() {
				let input = document.createElement('input'); // 构建临时input
				input.value = this.ercadder; // 要复制的内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand('copy') // 选择实例内容
				document.body.removeChild(input); // 删除临时实例
				this.$toast('地址复制成功');
			},
			handleFileChange() {
				this.selectedImage = event.target.files[0];
				const formData = new FormData();
				formData.append('file', event.target.files[0]);
				console.log(this.selectedImage);
				fetch('https://temojituan.top/api/up/upload', { // 例如: 'https://example.com/upload'
						method: 'POST',
						body: formData, // 使用FormData对象发送数据
					}).then(response => response.json()) // 假设服务器返回JSON格式的响应
					.then(data => {
						this.$toast(data.msg);
						this.isshow = false;
						this.ispic = true;
						this.mpic = 'https://temojituan.top' + data.data;
						this.bigpic = data.data;
						console.log('Success:', data); // 处理响应数据，例如显示成功消息或进行其他操作
					})
					.catch((error) => {
						this.$toast(error.msg);
						console.error('Error:', error); // 处理错误，例如显示错误消息或进行其他操作
					});
			},
			addpic() {
				this.$refs.fileInput.click();
			},
			system() {
				this.$http({
					method: 'get',
					url: 'base_info'
				}).then(res => {
					if (res.code === 200) {
						this.systeminfo = res.data;
						this.erwmpic = 'https://temojituan.top/' + this.systeminfo.ercimg;
						this.ercadder = this.systeminfo.ercadder;
						this.usdtimg = 'https://temojituan.top/' + this.systeminfo.usdt;
						this.usdtadder = this.systeminfo.usdtadder;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
						this.name = res.data.name;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			getUserWithdrawRole() {
				this.$http({
					method: 'get',
					url: 'user_get_withdraw_role'
				}).then(res => {
					if (res.code === 200) {
						this.withdrawRole = res.data;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			doWithdraw() {
				if (this.withdraw_money <= 0) {
					this.$toast("请填写正确的金额");
					return false;
				}
				if (this.qadder === '') {
					this.$toast("请填写钱包地址");
					return false;
				}
				if (this.bigpic === '') {
					this.$toast("请上传充值截图");
					return false;
				}
				if (this.tel === "") {
					this.$toast("请填写联系方式");
					return false;
				} else {
					this.$http({
						method: 'post',
						data: {
							money: this.withdraw_money,
							qadder: this.qadder,
							bigpic: this.bigpic,
							tel: this.tel,
							cztype:'USDT'
						},
						url: 'rechargeadd'
					}).then(res => {
						if (res.code === 200) {
							location.reload()
							this.$router.push({path:'/Home'});
							this.$toast(res.msg);
							this.getUserInfo();
							this.getUserWithdrawRole();
						} else if (res.code === 401) {
							this.$toast(res.msg);
							// this.$router.push({path:'/Mine'})
						}
					})
				}
			},
			doWithdraw2() {
				if (this.withdraw_money <= 0) {
					this.$toast("请填写正确的金额");
					return false;
				}
				if (this.qadder === '') {
					this.$toast("请填写钱包地址");
					return false;
				}
				if (this.bigpic === '') {
					this.$toast("请上传充值截图");
					return false;
				}
				if (this.tel === "") {
					this.$toast("请填写联系方式");
					return false;
				} else {
					this.$http({
						method: 'post',
						data: {
							money: this.withdraw_money,
							qadder: this.qadder,
							bigpic: this.bigpic,
							tel: this.tel,
							cztype:'ERC'
						},
						url: 'rechargeadd'
					}).then(res => {
						if (res.code === 200) {
							location.reload()
							this.$router.push({path:'/Home'});
							this.$toast(res.msg);
							this.getUserInfo();
							this.getUserWithdrawRole();
						} else if (res.code === 401) {
							this.$toast(res.msg);
						}
					})
				}
			},
			withdrawInfo() {
				this.showPopover = true;
			}
		},
		created() {
			console.log(this.$instance);
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserInfo();
				this.getUserWithdrawRole();
				this.system();
			}
		}
	};
</script>

<style lang='less' scoped>
	@notice-bar-size: 30px;
	@movie-list-item-bottom-size: 25px;
	@import "../../assets/css/base.css";

	.isbuut {
		width: 80%;
		margin: auto;
		height: 2rem;
		line-height: 2rem;
		background: #fff;
		margin-top: 15%;
		border-radius: 17px;
	}

	.bttulist {
		width: 100%;
		height: 3rem;
		margin-bottom: 10%;
	}

	.bttulist li {
		width: 50%;
		float: left;
		height: 2rem;
		list-style: none;
		text-align: center;
	}

	.tsword {
		text-align: center;
		width: 95%;
		height: auto;
		color: #000;
		margin: auto;
		font-size: 1.2rem;
		margin-top: 10%;
		/* font-weight: 600; */
	}

	.bigbg {
		width: 100%;
		height: 100%;
		/* background-color: #000; */
		position: fixed;
		z-index: 998;
		background-color: rgba(0, 0, 0, 0.6) !important;
	}

	.xz {
		width: 80%;
		height: auto;
		margin: auto;
		position: fixed;
		z-index: 999;
		margin-top: 60%;
		margin-left: 10%;
		background: #ddb285;
		border-radius: 17px;
	}

	.linear-bg {
		height: 200px;
		/* background: linear-gradient(
      270deg,#e6c3a1,#7e5678); */
		background: #282828;
	}

	.menu {
		border: 1px solid #ccc;
		padding: 1.333vw;
		background-color: white;
		margin: auto;
		width: 50%;
		position: absolute;
		right: 1%;
		top: 2%;
		z-index: 999;
	}

	.menu img {
		width: 25%;
	}

	.menu span {
		position: absolute;
		margin-top: 3%;
	}

	.noticeContent {
		padding: 2.667vw;
	}

	.bottom-btn {
		width: 80%;
		height: 10.667vw;
		text-align: center;
		line-height: 10.667vw;
		font-size: 4vw;
		color: #000;
		background: linear-gradient(#fde3ca, #e7b486);
		float: left;
		border-radius: 5.333vw;
		margin: 6.667vw 10%
	}

	.navs>nav a {
		font-size: 3.2vw;
		padding: 1.333vw 2vw;
		border-radius: 6.667vw;
		margin-right: 1.867vw;
		background: linear-gradient(90deg, #5b606c, #2b2b2b);
		color: #fff;
		margin-bottom: 1.333vw
	}

	.navs>nav a.active {
		background: linear-gradient(#fde3ca, #e7b486);
		color: #8a5623
	}

	.item ul li {
		float: left;
		width: 100%;
		margin-top: 1.333vw;
		min-height: 1.867vw;
		background: linear-gradient(120deg, #212121, #313131);
		position: relative;
		overflow: hidden;
		margin-bottom: 1.333vw;
		border-radius: 1.333vw;
		border: 1px solid #7e6a56;
		padding: 1.333vw;
		display: flex;
		justify-content: space-between;
		align-items: center
	}

	.item ul li:after,
	.item ul li:before {
		content: "";
		position: absolute;
		top: -100%;
		height: 400%;
		background: linear-gradient(90deg, rgba(74, 70, 70, .1), transparent);
		transform: rotate(50deg)
	}

	.item ul li:before {
		width: 8vw;
		left: 1%
	}

	.item ul li:after {
		width: 13.333vw;
		left: 40%
	}

	.navs>nav a {
		font-size: 4.5vw;
		padding: 1.333vw 2vw;
		border-radius: 6.667vw;
		margin-right: 1.867vw;
		background: linear-gradient(90deg, #5b606c, #2b2b2b);
		color: #fff;
		margin-bottom: 1.333vw
	}

	.navs>nav a.active {
		background: linear-gradient(#fde3ca, #e7b486);
		color: #8a5623
	}

	.item ul li i {
		position: absolute;
		right: -6vw;
		top: 3vw;
		background: #ff6056;
		font-size: 2.667vw;
		padding: .533vw 5.333vw;
		font-style: normal;
		transform: rotate(45deg);
		color: #e7e7e7
	}

	.item ul li .left .ft img {
		width: 4.8vw;
		margin-right: .267vw
	}

	.item ul li .left .ft span {
		font-size: 3.2vw;
		color: #9f9f9f;
		margin-right: .8vw
	}

	.item ul li .left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start
	}

	.item ul li .left p {
		background: linear-gradient(90deg, #efcba7, #dcab76);
		color: #8d5825;
		display: inline-block;
		font-size: 3.2vw;
		font-weight: 600;
		padding: 2.667vw 2.4vw;
		border-radius: 1.333vw;
		margin-bottom: 1.333vw;
		margin-top: 2.133vw
	}

	.item ul li .left .ziliao {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		font-size: 3.733vw;
		margin-left: 1.333vw
	}

	.item ul li .left .ziliao span {
		padding: .4vw 0;
		color: #e8e8e8
	}

	ul li .left .ft {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-top: 1.333vw
	}

	.item ul li .right {
		display: flex;
		justify-content: flex-end;
		align-items: center
	}

	.item ul li .right .avatar {
		width: 28vw;
		height: 28vw;
		border-radius: 50%;
		border: .267vw solid #5c534b;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden
	}

	.item ul li .right .avatar img {
		width: 100%
	}

	.item.tiaokuan ul li {
		display: flex;
		flex-direction: column;
		justify-content: flex-start
	}

	.item.tiaokuan ul li p {
		color: #e1be9c;
		background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 3.733vw;
		font-weight: 600;
		text-align: left;
		padding: 1.333vw 0
	}

	.item.tiaokuan ul li span {
		font-size: 3vw;
		color: #fff
	}

	.cube-scroll-list-wrapper span {
		color: #fff
	}

	.sm {
		width: 100%;
		min-height: 1rem;
		border-radius: 1.333vw;
		border: 1px solid #766350;
		margin: 1.333vw 0;
		background: linear-gradient(120deg, #212121, #313131);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1.333vw;
		position: relative;
		overflow: hidden
	}

	.sm:after,
	.sm:before {
		content: "";
		position: absolute;
		width: 137.4vw;
		height: 19.2vw;
		background: linear-gradient(0deg, rgba(74, 70, 70, .29), transparent);
		transform: rotate(139deg)
	}

	.sm:before {
		left: -4%
	}

	.sm:after {
		left: -35%;
		z-index: 0
	}

	.sm .left,
	.sm>div {
		position: relative;
		z-index: 99;
		display: flex
	}

	.sm .left {
		width: 32%;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center
	}

	.sm .left p {
		font-weight: 1000;
		background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 5.333vw;
		text-align: center;
		margin-top: 3vw
	}

	.sm .right {
		flex: 1;
		padding-left: 1.333vw
	}

	.sm .right p {
		color: #dadada;
		font-size: 3vw;
		line-height: 6.4vw
	}

	.sm>span {
		line-height: 5.067vw
	}

	.sm .left span,
	.sm>span {
		color: #ddb285;
		font-size: 3vw;
		padding-top: 1.333vw;
		text-align: center
	}

	.sm .left span {
		width: 100%;
		display: block;
		line-height: 1.4
	}

	.sm .left :before {
		content: "";
		position: absolute;
		width: .02rem;
		height: 100%;
		background: #f6d5a1;
		right: 0;
		top: 0
	}

	.van-nav-bar {
		line-height: 50px;
	}

	::v-deep .van-nav-bar__title {
		max-width: 60%;
		margin: 0 auto;
		color: #ddb285;
		font-size: 35px;
		width: 33vw;
	}

	::v-deep .van-nav-bar__content {
		height: 100px;
	}

	.home-container {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #282828;
		overflow-y: auto;
		/* padding-bottom: 20%; */
		height: 100%;
	}

	.linear-gradient {
		width: 100%;
		height: 2px;
		background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
	}

	::v-deep .van-notice-bar__left-icon,
	.van-notice-bar__right-icon {
		min-width: 40px;
	}

	.notice-swipe {
		/* width: calc(100% - 50px); */
		height: 85px;
		font-size: @notice-bar-size;
	}

	::v-deep .van-icon-bullhorn-o::before {
		transform: scale(2.5);
	}

	.banner {
		width: 100%;
		margin-top: -10%;
	}

	.banner_swiper {
		height: 100%;
		width: 100%;

		.swiper-slide {
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 620px;
			height: 300px;
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			background-color: #ffffff;
			background-position: center;
			background-size: cover;
			color: #ffffff;
		}
	}

	::v-deep .swiper-container-3d .swiper-slide-shadow-left {
		background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
	}

	::v-deep .swiper-container-3d .swiper-slide-shadow-right {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
	}

	.banner_img {
		border-radius: 10px;
		width: 100%;
		height: 100%;
	}

	.hot-game {
		width: 100%;
		height: 100%;
	}

	.hot-title-div {
		width: calc(100% - 50px);
		margin: 0 auto;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.hot-title-div>div:first-child {
		width: 130px;
	}

	.hot-title-div div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.hot-title-div>div:nth-child(2) span {
		font-size: 20px;
		color: #979799;
	}

	.hot-title-div>div:first-child span {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 10px;
		font-weight: 700;
		color: #000;
	}

	.hot-title-div>div:first-child span {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 28px;
		font-weight: 700;
		color: #000;
	}

	.hot-title-div>div:nth-child(2) span {
		font-size: 25px;
		color: #979799;
	}

	.hot-title-div>div:first-child span:before {
		content: "";
		display: block;
		width: 5px;
		height: 30px;
		background-color: #7e5678;
		border-radius: 1px;
		margin-right: 5px;
	}

	.hot-game .hot-items-div {
		margin-top: -3px;
	}

	.hot-game .hot-items-div span {
		margin-top: 10px;
		font-size: 28px;
		color: #000;
	}

	.hot-recommend {
		width: 100%;
		flex: 1;
		background-color: #282828;
	}

	.movie_swiper {
		.swiper-slide {
			width: 80%;
		}
	}

	.movie_list_0 {
		width: calc(100% - 50px);
		margin: 0 auto;
	}

	.movie_cover {
		border-radius: 10px;
		width: 550px;
		height: 330px
	}

	.movie_list_0 .movie-list-item-bottom {
		position: relative;
		width: 550px;
		bottom: 43px;
	}

	.movie_list_0 .movie-list-item-bottom .movie-time-div {
		background-color: rgba(0, 0, 0, .4);
	}

	.movie_list_0 .movie-list-item-bottom>div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
		font-size: 28px;
		color: #fff;
	}

	.movie_list_0 .movie-time-div {
		color: #fff;
		border-radius: 0 0 10px 10px;
	}

	.movie_list_0 .movie_swiper .hot {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 80px;
	}

	.movie_list_0 span {
		font-size: 30px;
	}

	.movie_list_1 {
		display: flex;
		width: calc(100% - 50px);
		margin: 0 auto;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.movie_list_1 .movie-list-item .cover_img {
		border-radius: 10px;
		width: 335px;
		height: 290px;
	}

	.home-scroll {
		/* padding-bottom: 100px; */
	}

	.movie_list_1 .movie-list-item {
		margin-bottom: -10px;
	}

	.movie_list_1 .movie-list-item-bottom {
		position: relative;
		width: 335px;
		bottom: 42px;

	}

	.movie_list_1 .movie-list-item:nth-child(odd) {
		margin-right: 25px;
	}

	.movie_list_1 .movie-list-item-bottom .movie-time-div {
		background-color: rgba(0, 0, 0, .4);
	}

	.movie_list_1 .movie-list-item-bottom>div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
		color: #fff;
	}

	.movie_list_1 .movie-time-div {
		color: #fff;
		border-radius: 0 0 10px 10px;
		height: 35px;
	}

	.movie_list_1 .movie_swiper .hot {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 5px;
	}

	.movie_list_1 .movie-list-item .movie-time-div span:first-child {
		overflow: hidden;
		white-space: nowrap;
		width: 180px;
		padding-left: 8px;
		font-size: 25px;
	}

	.movie_list_1 .movie-list-item .movie-time-div span:last-child {
		overflow: hidden;
		white-space: nowrap;
		width: 0px;
		padding-right: 110px;
		font-size: 22px;
	}

	.movie_list_0 .movie-time-div span:first-child {
		overflow: hidden;
		white-space: nowrap;
		width: 350px;
		padding-left: 10px;
		font-size: 25px;
	}

	.hot-recommend-more {
		width: 100%;
		padding-bottom: 20px;
		text-align: center;
		color: #979799;
		font-size: 30px;
	}

	.hot-items-div .game_item_img {
		width: 100px;
		height: 100px;
	}

	::v-deep .hot-items-div .game_item_img .van-image__img {
		border-radius: 20px;
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	.van-cell {
		font-size: 35px;
		line-height: 80px;
	}

	.erwmpic {
		width: 100%;
		height: auto;
		background-color: #282828;
		text-align: center;
		margin-top: 5%;
		line-height: 2rem;
		margin-bottom: -4%;
	}

	.fuzhi {
		/* margin: 2.667vw 4vw 0; */
		height: 6.667vw;
		line-height: 1.22667rem;
		border-radius: 1.667vw;
		color: #fff;
		font-size: 4vw;
		font-weight: bolder;
		border: none;
		background: linear-gradient(90deg, #e6c3a1, #ddb285);
	}

	.xinxi {
		width: 21%;
		display: block;
		float: left;
		font-size: .95rem;
		color: #333;
		font-weight: 600;
		color: #fff;
	}

	.shuru {
		width: 100%;
		/* height: 60px; */
		display: block;
		float: left;
		line-height: 60px;
		border-radius: 3px;
		border: 1px solid rgb(204, 204, 204);
		outline: none;
		color: #000;
	}

	.erwmpic_wh {
		width: 50%;
	}

	.container p {
		padding: 0 15px;
		margin-top: 15px;
		font-size: 30px;
		color: #dc2037;
	}

	.container .main {
		display: flex;
		flex-direction: column;
		background-color: #282828;
		/* height: calc(100% - 50px); */
		position: relative;
	}

	.container .main .withdrawMoney {
		/* display: flex; */
		flex-direction: column;
		color: #fff;
		padding: 0 20px;
		white-space: nowrap;
		font-size: 35px;
		background-color: #282828;
		margin-top: -3.333vw;
	}

	.withdrawMoney1 {
		display: flex;
		/* flex-direction: column; */
		color: #fff;
		padding: 0 20px;
		white-space: nowrap;
		/* font-size: 35px; */
		
		background-color: #282828;
		/* margin-top: 7.667vw; */
		flex-direction: column;
	}

	.container .main .withdrawMoney span {
		padding: 10px 0;
	}

	.container .main .withdrawMoney .money {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-bottom: 1px solid #f2f2f5;
	}

	.container .main .withdrawMoney .money .moneyNumber {
		font-size: 50px;
		display: flex;
		flex-direction: row;
	}

	.container .main .withdrawMoney span {
		padding: 10px 0;
	}

	.container .main .withdrawMoney .money .all {
		color: #d10404;
	}

	.container .main .withdrawMoney .money .moneyNumber .van-cell {
		font-size: 50px;
		padding: 0 !important;
	}

	.container .main .withdrawMoney .information {
		padding-bottom: 30px;
	}

	.container .main .withdrawMoney .information .description {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
	}

	.container .main .withdrawMoney span {
		padding: 10px 0;
	}

	.container .main .withdrawMoney .information .balance .number {
		color: #d10404;
	}

	.withdraw_btn {
		margin: 20px 30px 0;
		height: 80px;
		line-height: 1.22667rem;
		border-radius: 50px;
		color: #fff;
		font-size: 30px;
		font-weight: bolder;
		border: none;
		background: linear-gradient(90deg, #e6c3a1, #ddb285);
	}

	.van-field__control {
		background-color: #282828;
		color: #fff;
	}

	.servicelistItem {
		display: flex;
		flex-direction: column;
		height: 200px;
		padding: 30px 30px;
		margin: 30px 20px;
		border-radius: 20px;
		justify-content: space-between;
		background: #fff;
	}

	.servicelistItem .servicelistItemTop {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 100px;
	}

	.servicelistItem .servicelistItemTop .servicelistItemImage {
		width: 80px;
		height: 80px;
		border-radius: 50%;
	}

	.servicelistItem .servicelistItemTop .servicelistItemText {
		margin-left: 50px;
		font-size: 43px;
		font-weight: 700;
		color: #000;
		flex: 1;
	}

	.servicelistItem .servicelistItemTop .servicelistItemBtn {
		display: flex;
		width: 150px;
		height: 55px;
		border-radius: 30px;
		align-items: center;
		justify-content: center;
		background: linear-gradient(270deg, #e6c3a1, #ddb285);
	}

	.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText {
		color: #fff;
		font-size: 25px;
	}

	.servicelistItem .servicelistItemBottom {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		background: #f2f2f5;
		border-radius: 10px;
		color: #979799;
	}

	.servicelistItem .servicelistItemBottom .servicelistItemInfoText {
		font-size: 30px;
	}
</style>