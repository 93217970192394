<template>
<div class="convention-hall page">
  <van-nav-bar
      class="nav-bar"
      title="游戏大厅"
  />
  <div class="convention-item">
    <div class="left">
      <van-sidebar @change="onChange" v-model="activeKey">
        <van-sidebar-item title="全部" />
        <van-sidebar-item v-for="(v,key) in lotteryitem" :key="key" :title="v.name" />
      </van-sidebar>
    </div>
    <div class="right">
      <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
        <van-grid :column-num="2">
          <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
            <van-image class="game_item_img" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
            <span>{{v.name}}</span>
            <span>{{v.desc}}</span>
          </van-grid-item>
        </van-grid>
		<div>
			<ul style="margin: 0; padding: 0; list-style: none;">
				<li class="jieshao">
					<div>
						<p class="jieshao1">玩法介绍</p>
						<p style="font-size: 3vw; color: #fff;white-space: pre-line;">{{gamepay}}</p>
					<!-- <p style="font-size: 3vw; color: #fff;  white-space: pre-line;">竞猜规则：</span>
					<span style="font-size: 3vw; color: #fff; white-space: pre-line;">使用3个六面骰子（1-6点）进行随机开奖。</span>
					<span style="font-size: 3vw; color: #fff; white-space: pre-line;">一、和值玩法</span>
					<span style="font-size: 3vw; color: #fff;">下注三个骰子点数的总和。</span>
					<span style="font-size: 3vw; color: #fff;">例如：开奖 2、4、5，和值 11，如果你买的是11，则中奖。中奖赔率不同比例，通常 和值3、18赔率最高（因为只有一种组合能出现）。</span>
					<span style="font-size: 3vw; color: #fff;">二、大/小玩法</span>
					<span style="font-size: 3vw; color: #fff;">小：3-8  （点数总和）</span>
					<span style="font-size: 3vw; color: #fff;">大：11-18（点数总和）</span>
					<span style="font-size: 3vw; color: #fff;">三、单/双玩法</span>
					<span style="font-size: 3vw; color: #fff;">单（和值是奇数，例如3、5、7…）</span>
					<span style="font-size: 3vw; color: #fff;">双（和值是偶数，例如2、4、6…）</span> -->
					</div>
				</li>
			</ul>
		</div>
		<div style="margin-top: 60px;"></div>
      </van-pull-refresh>
    </div>
  </div>
</div>

</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
	gamepay:'',
      gameitem: [{},{},{},{}],
      lotteryitem: [{},{},{},{}],
      isLoading: false,
      activeKey: 0,
    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
      }, 500);
    },
	system(){
			this.$http({
			method: 'get',
			url: 'base_info'
			}).then(res=>{
			if(res.code === 200){
			this.gamepay = res.data.gamepay;
			console.log(this.gamepay);
			}else if(res.code ===401){
			this.$toast(res.msg);
			}
			})
		},
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data:{class:index},
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
      })
    },
    getLotteryItem(){
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res=>{
        this.lotteryitem = res.data;
      })
    }
  },
  created() {
	if(!localStorage.getItem('token')){
		this.$router.push({path:'/Login'})
	}
    this.getGameItem();//获取首页游戏列表
    this.getLotteryItem();
	this.system();
	console.log(localStorage.getItem('isyuyan'));
  }
};
</script>

<style lang='less' scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #212121;
}
.jieshao{
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
	float: left;
	width: 100%;
	margin-top: 1.333vw;
	min-height: 1.867vw;
	background: linear-gradient(120deg, #212121, #313131);
	position: relative;
	overflow: hidden;
	margin-bottom: 1.333vw;
	border-radius: 1.333vw;
	border: 1px solid #7e6a56;
	padding: 1.333vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.jieshao1{
	color: #e1be9c;
	background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 3.733vw;
	font-weight: 600;
	text-align: left;
	padding: 1.333vw 0;
}
.nav-bar{
  background: linear-gradient(
      90deg,#212121,#212121);
  height: 100px;

}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #fff;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #fff;
  font-size: 35px;
  text-align: center;
}
.van-sidebar-item{
  font-size: 35px;
  text-align: center;
  padding: 50px;
  background-color: #212121;
  color: #fff;
}
.van-sidebar-item--select, .van-sidebar-item--select:active {
  /* background-color: #212121; */
      background: rgba(144, 185, 255, .1411764705882353);
}
.convention-item{
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}
.convention-hall{
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #212121;
}
.convention-item .left{
  height: 100%;
  background-color: #212121;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right{
  height: 100%;
  flex: 1;
  background-color: #282828;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right .list-wrapper{
  padding: 20px 20px;
  min-height: 100vh;
  
}
.convention-item .right .list-wrapper .game_item_img{
  width: 200px;
  height: 200px;
}
.convention-item .right .list-wrapper span{
  margin-top: 10px;
  font-size: 30px;
  color: #000;
}
.convention-item .right .list-wrapper span:last-child{
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}
.van-grid-item {
  padding: 10px;

}
::v-deep .van-grid-item__content--center {
  border-radius: 15px;
}
::v-deep .van-image__img{
  border-radius: 40px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

</style>
