<template>
  <div class="bg-container page">
      <img class="bg-img" src="img/login/login-bg.jpg">
      <div class="bg-wrapper">
		<div @click="showMenu = !showMenu" style="width:24%; right;color: #fff;position: fixed;right: 2%;margin-top: 3%;z-index: 999;">
			Language conversion
		</div>
		<div v-if="showMenu" class="menu">
			<p @click="lang('english')"><img src="../../../public/img/gq/mg.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;English</span></p>
			<p @click="lang('korean')"><img src="../../../public/img/gq/hg.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Korea</span></p>
			<p @click="lang('arabic')"><img src="../../../public/img/gq/alb.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Arabic</span></p>
			<p @click="lang('vietnamese')"><img src="../../../public/img/gq/yn.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Vietnam</span></p>
			<p @click="lang('chinese_traditional')"><img src="../../../public/img/gq/xg.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Hong kong</span></p>
			<p @click="lang('japanese')"><img src="../../../public/img/gq/rb.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Japan</span></p>
			<p @click="lang('russian')"><img src="../../../public/img/gq/els.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Russian</span></p>
		</div>
        <div class="login">
          <van-nav-bar class="nav-bar">
            <template #left>
              <van-icon name="arrow-left" color="#fff" @click="back()"/>
            </template>
          </van-nav-bar>
          <div class="wrapper">
            <div class="logo-container">
              <div class="logo-wrapper">
                <!-- <img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !==undefined ?this.$store.getters.getBaseInfo.ico:'/img/null.png'"> -->
              </div>
            </div>
            <div class="title">登录</div>
            <div class="loginForm" style="color: aqua;">
              <van-field style="color: blue;" v-model="username"  clearable input-align="center" class="input"  placeholder="请输入用户名" />
              <van-field
                  v-model="password"
                  :type="passwordType"
                  input-align="center"
                  class="input"
                  placeholder="请输入密码">
                <template slot="right-icon">
                  <van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/>
                </template>
              </van-field>
              <div class="reset-text">
                <span>忘记密码？</span>
              </div>
              <div @click="toRegister()" class="register-text">
                <span>没有账户？马上注册</span>
              </div>
              <van-button class="login-btn"   type="primary" size="normal" @click="doLogin()">登录</van-button>
            </div>
          </div>
        </div>
		<div class="floating-element">
			<a href="https://sex99.vip/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=1&groupid=0&special=1"><img style="height: 100px; width: 100px;" src="img/mine/57B135B9E25ADADEEE14B54E8A31D47B.gif" alt="" /></a>
		</div>
      </div>
    </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      username: '',
      password: this.inputValue,
      passwordType: 'password',
	showMenu:'',
    };
  },
  methods: {
	lang(e){
		var langs = e;
		
		localStorage.setItem('isyuyan', langs);
		localStorage.setItem('men', 1);
		this.$translate.changeLanguage(langs);
		//this.$translate.execute();
		console.log(langs);
	},
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
    toRegister(){
      this.$router.push("Register")
    },
    doLogin() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast("请输入用户名！");
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast("请输入密码！");
        return false;
      }
		
      this.$http({
        method: 'get',
        data:{username:this.username,password:this.password},
        url: 'member_login'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data.id)
            this.$router.push("Mine")
          }else {
            this.$toast(res.msg);
          }
      })
    }
  },
	mounted () {
		var seesionlang  = localStorage.getItem('isyuyan');
		if(seesionlang){
			this.$translate.changeLanguage(seesionlang);
		}else{
			this.$translate.changeLanguage('english');
		}
		//this.$translate.changeLanguage();
		this.$translate.execute();
		
	},
	beforeunload(){
		
	},
  created() {
      if(localStorage.getItem('token')){
        return window.history.back();
      }
	if(localStorage.getItem('men')!=1){
		this.showMenu=true;
	}
		console.log(localStorage.getItem('men'));
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.menu {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: white;
  margin: auto;
  width: 50%;
  position: absolute;
  right: 3%;
  top: 6%;
  z-index: 99;
}
.menu img{width: 25%;}
.menu span{position: absolute;
    margin-top: 3%;}
.login{
  height: 100%;
}
.bg-container .bg-wrapper .login .nav-bar{
  background: 0 0
  
}
/* .bg-container .bg-wrapper {

    background: #fff;
} */

.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container{
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper{
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.login .wrapper .title{
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.login .wrapper .loginForm{
  padding: 60px;
}
.login .wrapper .loginForm .input{
  padding: 10px 20px;
  margin-top: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.login .wrapper .loginForm .reset-text{
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login .wrapper .loginForm .reset-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.login .wrapper .loginForm .register-text{
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .wrapper .loginForm .register-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.login .wrapper .loginForm .active{

}
.login .wrapper .loginForm .login-btn{
  margin-top: 85px;
  width: 100%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background-color: #d22bd9c2;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}
.floating-element {
  position: fixed;
  bottom: 20px; /* 距离底部的距离 */
  right: 20px; /* 距离右边的距离 */
  width: 29.333vw;
  height: 33.667vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
