<template>
  <div class="container page">
	<van-nav-bar :title="提示" class="nav-bar">
		<template #left>
			<van-icon name="arrow-left" color="#fff" @click="back()"/>
		</template>
	</van-nav-bar>
    <div class="tsword">
		注意<br>试看时间已结束！<br>需要开通高级会员才可以免费观看。
	</div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
		
    };
  },
  methods: {
	
    back(){
      this.$router.push({path:'Video'})
    },
   
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }

  },
  destroyed () {
    
  }
};
</script>

<style scoped>
.tsword{
width: 80%;
height: 25%;
margin: auto;
background: #bbb17f;
margin-top: 10%;
border-radius: 2%;
text-align: center;
color: #fff;
line-height: 1.8rem;
padding-top: 10%;
}
.video-js {
  width: 100%;
  /*height: 420px;*/
  font-size: 24px;
}
.movie-content{
  flex: 1;
  overflow-y: auto;
}
.movie-content .movie-descript{
  width: 100%;
  height: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}
.movie-content .movie-descript p{
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-descript span{
  color: #979799;
}
.movie-content .movie-body{
  width: calc(100% - 20px);
  margin: 0 auto;
}
::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.movie-content .movie-body .movie-title{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie-content .movie-body .movie-title>div:first-child {
  width: 200px;
}
.movie-content .movie-body .movie-title>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-body .movie-title>div:first-child span:before {
  content: "";
  display: block;
  width: 8px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 25px;
  margin-right: 10px;
}
.movie-play-item{
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
}
.movie-play-item>div{
  height: 100%;
}
.movie-play-item>div:first-child {
  width: 200px;
  position: relative;
}
.movie-play-item>div:first-child>img{
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.movie-play-item>div:first-child>div{
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgba(0,0,0,.4);
  border-radius: 0 0 0 10px;
}
.movie-play-item>div:first-child>div .van-count-down {
  color: #fff;
  font-size: 25px;
}
.movie-play-item>div:nth-child(2) p{
  width: 500px;
  height: 60px;
  font-size: 30px;
  line-height: 32px;
  word-break: break-all;
  overflow: hidden;
  color: #000;
}
.movie-play-item>div:nth-child(2) span{
  color: #000;
}
.movie-play-item>div:nth-child(2) {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
</style>