<template>
	<div class="bottom-nav">
	<van-tabbar v-if="show" v-model="active" active-color="#e6b98a" :border="true" inactive-color="#979799">
		<van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
			<span>{{ k.title }}</span>
			<template #icon="props">
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key !== 2" />
				<!-- <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" style="height: 4rem;" v-show="key === 2" /> -->
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key === 2" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			messages: [],
			item: [
				{
					router: '/Home',
					title: '首页',
					
					icon: {
						active: '../../../img/footer/homec.jpg',
						noactive: '../../../img/footer/home.jpg'
					}
				},
				{
					router: '/Game',
					title: '开奖',
					icon: {
						active: '../../../img/footer/reservec.jpg',
						noactive: '../../../img/footer/reserve.jpg'
					}
				},
				{
					router: '/Video',
					title: '视频',
					icon: {
						active: '../../../img/footer/videoc.jpg',
						noactive: '../../../img/footer/video.jpg'
					}
				},
				{
					router: '/Mine',
					title: '我的',
					icon: {
						active: '../../../img/footer/myc.jpg',
						noactive: '../../../img/footer/my.jpg'
					}
				}
			]
		};
	},
	methods: {


	},
	mounted() {
		
	},
	watch: {
		$route(to) {
			if (to.name == 'home') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'game') {
				this.active = 1;
				this.show = true;
			// } else if (to.name == 'choose') {
			// 	this.active = 2;
			// 	this.show = true;
			} else if (to.name == 'video') {
				this.active = 2;
				this.show = true;
			} else if (to.name == 'mine') {
				this.active = 3;
				this.show = true;
			} else {
				this.show = false;
				
			}
		}
	},
	created() {		
		
		if (this.$route.name == 'home') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'game') {
			this.active = 1;
			this.show = true;
		// } else if (this.$route.name == 'choose') {
		// 	this.active = 2;
		// 	this.show = true;
		} else if (this.$route.name == 'video') {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == 'mine') {
			this.active = 3;
			this.show = true;
		} else {
			this.show = false;
			this.ismsg = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 100px;
@tabbar-img: 50px;
.quan{
width: 1.5rem;
height: 1.5rem;
background-color: red;
position: absolute;
z-index: 9;
border-radius: 1.5rem;
bottom: 6%;
right: 6%;
color: #fff;
line-height: 1.5rem;
text-align: center;
font-size: .8rem;
}
.van-tabbar {
	height: @tabbar-height;
	background: #282828;
}
.van-tabbar-item__icon img {
	height: @tabbar-img;
	// height: 5vh;
}
.van-tabbar-item {
	font-size: 22px;
	background: #282828;
	
}
.tui {
	width: 4rem;
	margin-top: -10.333vw;
	background-color: white;
	border-radius: 50%;
	border: 10px solid white;
	z-index: 10;
}
[class*='van-hairline']::after {
	border: none !important;
}
</style>
