<template>
  <div class="home-container">
	<div class="bigbg"  v-if="istsok">
		<div class="xz">
			<div class="tsword">支付198美元开通高级会员权限！</div>
			<div class="bttulist">
				<li><div class="isbuut" @click="isclose">取消</div></li>
				<li><div class="isbuut" @click="isusersupp">确定</div></li>
			</div>
		</div>
	</div>
    <div class="linear-bg">
		<van-nav-bar class="nav-bar" style="display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    " title="私密性爱" />
		
	<div @click="showMenu = !showMenu" style="width: 20%;color: #ddb285;position: absolute;right: 1%;margin-top: -10%;z-index: 999;
}">Language conversion</div>
	<div v-if="showMenu" class="menu">
	<p @click="lang('english')"><img src="../../../public/img/gq/mg.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;English</span></p>
	<p @click="lang('korean')"><img src="../../../public/img/gq/hg.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Korea</span></p>
	<p @click="lang('arabic')"><img src="../../../public/img/gq/alb.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Arabic</span></p>
	<p @click="lang('vietnamese')"><img src="../../../public/img/gq/yn.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Vietnam</span></p>
	<p @click="lang('chinese_traditional')"><img src="../../../public/img/gq/xg.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Hong kong</span></p>
	<p @click="lang('japanese')"><img src="../../../public/img/gq/rb.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Japan</span></p>
	<p @click="lang('russian')"><img src="../../../public/img/gq/els.svg"><span style="font-size: 18px; font-weight: 600;">&nbsp;Russian</span></p>
	</div>
	</div>

    <div class="home-scroll">
      <div class="banner">
		<!-- <div class="noticeContent"> -->
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
      <!--  <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#ffffff"
            color="#7e5678"
            :text="this.notice"
        /> -->
        <div class="linear-gradient"></div>
      </div>
			
			<div class="noticeContent">
			<div class="sm">
				<div class="sm>div">
				<div class="left">
				<p class="p"> 私密性爱</p>	
				<span>全国第一福利品牌</span>
				</div>
				<div class="right">
					<span style="color: #dadada; font-size: 3vw; line-height: 6.4vw;">为了会员权益，网站只展示 1% 资源并且不定期更新只为展示实力，如需永久观看视频或预约美女请开通高级会员，联系客服接待。</span>
				</div>
				</div>
				<span style="color: #ddb285;font-size: 3vw;padding-top: 1.333vw;text-align: center;">本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧</span>
			</div>
			<div class="navs">
				<nav style="padding-top: 2vw;display: flex;justify-content: flex-start;flex-wrap: wrap;">
					<a   :class="{ 'active': active == 0 }" @click="active = 0">平台资源</a>
					<a  :class="{ 'active': active == 1 }" @click="active = 1">服务条款</a>
					<!-- <a @click="jumpappurl()">APP下载</a> -->
					<img @click="jumpappurl()" style="height: 30px; width: 100px; margin-right: 1.867vw;" src="../../../public/img/app/appstore.png" alt="" />
					<img @click="jumpappurl()" style="height: 30px; width: 100px;" src="../../../public/img/app/playstore.png" alt="" />
				</nav>
			</div>
			<div class="item" v-if="active == 0">
                <ul>
				<li @click="addgo(item.id)" v-for="(item, index) in datalist" :key="index">
					<i>已认证</i>
					<div class="left">
						<p>ID: 888{{ item.id }}</p>
						<div class="ziliao">
							<span>昵称：{{ item.xuanfei_name }}</span>
							<span>类型：{{ item.type }}</span>
							<span>距离：查看详情</span>
							<span>服务项目：开通高级VIP</span>
						</div>
						<!-- <div class="ft">
							<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII=" alt="" />
						<span>895k</span>
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9TV1eiAU5YHPzYBmDuhiRcn79xK4z4WF5LeAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg==" alt="" />
						<span>863k</span>
						</div> -->
					</div>
					<div class="right">
						<div class="avatar">
							<img style="height: 100%;" :src="item.img_url" alt="" />
						</div>
					</div>
				</li>
				</ul>
			</div>
			<div style="margin-top: 20px;" v-if="active == 0" class="bottom-btn" @click="ismore()">查看更多</div>
			<div v-if="active == 1" style="height:auto;">
				
				<div class="item">
					<ul>
						<li style="display: block;font-size: .9rem;">
							<div style="width: 95%;line-height: 3rem;margin:auto;color: #ebbe93;">平台资源</div>
							<div style="width: 95%;line-height: 1.5rem;margin:auto;color: #fff;">{{systeminfo.ptzy}}</div>
							<div style="width: 95%;line-height: 3rem;margin:auto;color: #ebbe93;">服务范围</div>
							<div style="width: 95%;line-height: 1.5rem;margin:auto;color: #fff;margin-bottom: 2%;">{{systeminfo.fwfw}}</div>
						</li>
					</ul>
					<!-- <div style="margin-top: 100px;"></div> -->
				</div>
				<!-- <div style="margin-top: 100px;"></div> -->
			</div>
			<div style="margin-top: 1px; color: #282828;">0</div> 
			</div>
			
    </div>
  </div>
  <!-- </div>  -->
</template>

<script>
export default {
  data() {
    return {
		language: 'en',
		notice: "获取中......",
		active:0,
		banners: [],
		basicData:[],
		usermeony:'',
		systeminfo:[],
		appurl:'',
		istsok:false,
		gameitem: [{},{},{},{}],
		movielist_0: [{},{},{},{}],
		movielist_1: [{},{},{},{},{},{},{},{}],
		datalist: [],
		isLoading: false,
		showMenu:false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
	issuper:"",
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
	isclose(){
		this.istsok=false;
		
	},
	isusersupp(){
		if(this.usermeony<198 && this.issuper===0){
			console.log(this.usermeony);
		this.$toast("账户余额不足，请先充值！");
		setTimeout(() => {
			this.$router.push({path:'/Recharge'});
			}, 3000); 

		}
		if(this.usermeony>=198 && this.issuper===0){
			this.$http({
				method: 'get',
				url: 'set_vip'
				}).then(res=>{
				if(res.code === 200){
				this.$toast(res.msg);
				
				setTimeout(() => {
					this.$router.push({path:'/Choose'});
				}, 3000);
				
				}else if(res.code ===401){
				this.$toast(res.msg);
				}
			})
		}
		
	},
	lang(e){
			var langs = e;
			localStorage.setItem('isyuyan', langs);
			this.$translate.changeLanguage(langs);
			//this.$translate.execute();
			this.showMenu=false;
			console.log(langs);
	},
	jumpappurl(){
		this.$http({
		method: 'get',
		url: 'base_info'
		}).then(res=>{
		if(res.code === 200){
		window.location.href = res.data.appurl;
		}else if(res.code ===401){
		this.$toast(res.msg);
		}
		})
		
		
	},
	addgo(id) {
		if (!localStorage.getItem('token')) {
		this.$router.push({ path: '/Login' });
		} else {
		this.$router.push({ path: `/profile?id=${id}` });
		}
	},
    gotoMenu(router){
      this.$router.replace(router)
    },
	getxuanfeilist() {
		this.$http({
		method: 'get',
		url: 'xuanfeilist',
		data: { id: this.$route.query.id || 1,top:1 }
		}).then(res => {
		this.datalist = res.data.slice(0, 10);
		});
	},
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("刷新成功");
      }, 500);
    },
	system(){
		this.$http({
		method: 'get',
		url: 'base_info'
		}).then(res=>{
		if(res.code === 200){
		this.systeminfo = res.data;
		}else if(res.code ===401){
		this.$toast(res.msg);
		}
		})
	},
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner();//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
		this.getxuanfeilist();
		this.getUserInfo();
		this.system();
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getBanner(){
		this.$http({
		method: 'get',
		url: 'banners_list'
		}).then(res=>{
		if(res.code === 200){
		this.banners = res.data;
		}else if(res.code ===401){
		this.$toast(res.msg);
		}
		})

    },
	getUserInfo(){
		this.$http({
		method: 'get',
		url: 'user_info'
		}).then(res=>{
		if(res.code === 200){
		this.userInfo = res.data;
		this.usermeony = res.data.money;
		if(this.userInfo.issuper===0){
			this.issuper =0;
		}else{
			this.issuper =1;
		}
		
		}else if(res.code ===401){
		this.$toast(res.msg);
		}
		})
	},
	ismore(){
		console.log(this.issuper);
		if(this.issuper===0){
			
			this.istsok=true;
			
			
			//this.$toast("支付198美元开通高级会员权限！");
		}else{
			this.$router.push({path:'/Choose'})
		}
	},
  },
  mounted () {
	var seesionlang  = localStorage.getItem('isyuyan');
	if(seesionlang){
		this.$translate.changeLanguage(seesionlang);
	}else{
		this.$translate.changeLanguage('english');
	}
	//this.$translate.changeLanguage();
	this.$translate.execute();
  },
  created() {
	if(!localStorage.getItem('token')){
		this.$router.push({path:'/Login'})
	}
    this.getBasicConfig();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.isbuut{
	width: 80%;
	margin: auto;
	height: 2rem;
	line-height: 2rem;
	background: #fff;
	margin-top: 15%;
	border-radius: 17px;
}
.bttulist{
	width: 100%;
	height: 3rem;margin-bottom: 10%;
}
.bttulist li{width: 50%;float: left;height: 2rem;list-style: none;text-align: center;}
.tsword{
	text-align: center;
	width: 95%;
	height: auto;
	color: #000;
	margin: auto;
	font-size: 1.2rem;
	margin-top: 10%;
	/* font-weight: 600; */
}
.bigbg{width: 100%;
    height: 100%;
    /* background-color: #000; */
    position: fixed;
    z-index: 998;
	background-color:rgba(0,0,0,0.6)!important;
	}
.xz{
	width: 80%;
    height: auto;
    margin: auto;
    position: fixed;
    z-index: 999;
    margin-top: 60%;
    margin-left: 10%;
    background:#ddb285;
	border-radius: 17px;
	}
.linear-bg{
  height: 200px;
 /* background: linear-gradient(
      270deg,#e6c3a1,#7e5678); */
background: #282828;
}
.menu {
     border: 1px solid #ccc;
     padding: 1.333vw;
     background-color: white;
     margin: auto;
     width: 50%;
     position: absolute;
     right: 1%;
     top: 2%;
     z-index: 999;
}
.menu img{width: 25%;}
.menu span{position: absolute;
    margin-top: 3%;}
.noticeContent {
    padding: 2.667vw;
}
.bottom-btn {
    width: 80%;
    height: 10.667vw;
    text-align: center;
    line-height: 10.667vw;
    font-size: 4vw;
    color: #000;
    background: linear-gradient(#fde3ca,#e7b486);
    float: left;
    border-radius: 5.333vw;
    margin: 6.667vw 10%
}
.navs>nav a {
    font-size: 3.2vw;
    padding: 1.333vw 2vw;
    border-radius: 6.667vw;
    margin-right: 1.867vw;
    background: linear-gradient(90deg,#5b606c,#2b2b2b);
    color: #fff;
    margin-bottom: 1.333vw
}

.navs>nav a.active {
    background: linear-gradient(#fde3ca,#e7b486);
    color: #8a5623
}
.item ul li {
    float: left;
    width: 100%;
    margin-top: 1.333vw;
    min-height: 1.867vw;
    background: linear-gradient(120deg,#212121,#313131);
    position: relative;
    overflow: hidden;
    margin-bottom: 1.333vw;
    border-radius: 1.333vw;
    border: 1px solid #7e6a56;
    padding: 1.333vw;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.item ul li:after,.item ul li:before {
    content: "";
    position: absolute;
    top: -100%;
    height: 400%;
    background: linear-gradient(90deg,rgba(74,70,70,.1),transparent);
    transform: rotate(50deg)
}

.item ul li:before {
    width: 8vw;
    left: 1%
}

.item ul li:after {
    width: 13.333vw;
    left: 40%
}

.navs>nav a {
    font-size: 3.2vw;
    padding: 1.333vw 2vw;
    border-radius: 6.667vw;
    margin-right: 1.867vw;
    background: linear-gradient(90deg,#5b606c,#2b2b2b);
    color: #fff;
    margin-bottom: 1.333vw
}

.navs>nav a.active {
    background: linear-gradient(#fde3ca,#e7b486);
    color: #8a5623
}

.item ul li i {
    position: absolute;
    right: -6vw;
    top: 3vw;
    background: #ff6056;
    font-size: 2.667vw;
    padding: .533vw 5.333vw;
    font-style: normal;
    transform: rotate(45deg);
    color: #e7e7e7
}

.item ul li .left .ft img {
    width: 4.8vw;
    margin-right: .267vw
}

.item ul li .left .ft span {
    font-size: 3.2vw;
    color: #9f9f9f;
    margin-right: .8vw
}

.item ul li .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start
}

.item ul li .left p {
    background: linear-gradient(90deg,#efcba7,#dcab76);
    color: #8d5825;
    display: inline-block;
    font-size: 3.2vw;
    font-weight: 600;
    padding: 2.667vw 2.4vw;
    border-radius: 1.333vw;
    margin-bottom: 1.333vw;
    margin-top: 2.133vw
}

.item ul li .left .ziliao {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 3.733vw;
    margin-left: 1.333vw
}

.item ul li .left .ziliao span {
    padding: .4vw 0;
    color: #e8e8e8
}

ul li .left .ft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1.333vw
}

.item ul li .right {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.item ul li .right .avatar {
    width: 28vw;
    height: 28vw;
    border-radius: 50%;
    border: .267vw solid #5c534b;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.item ul li .right .avatar img {
    width: 100%
}

.item.tiaokuan ul li {
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}

.item.tiaokuan ul li p {
    color: #e1be9c;
    background-image: -webkit-linear-gradient(top,#f8dbae,#edbd71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.733vw;
    font-weight: 600;
    text-align: left;
    padding: 1.333vw 0
}

.item.tiaokuan ul li span {
    font-size: 3vw;
    color: #fff
}

.cube-scroll-list-wrapper span {
    color: #fff
}

.sm {
    width: 100%;
    min-height: 1rem;
    border-radius: 1.333vw;
    border: 1px solid #766350;
    margin: 1.333vw 0;
    background: linear-gradient(120deg,#212121,#313131);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.333vw;
    position: relative;
    overflow: hidden
}

.sm:after,.sm:before {
    content: "";
    position: absolute;
    width: 137.4vw;
    height: 19.2vw;
    background: linear-gradient(0deg,rgba(74,70,70,.29),transparent);
    transform: rotate(139deg)
}

.sm:before {
    left: -4%
}

.sm:after {
    left: -35%;
    z-index: 0
}

.sm .left,.sm>div {
    position: relative;
    z-index: 99;
    display: flex
}

.sm .left {
    width: 32%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center
}

.sm .left p {
    font-weight: 1000;
    background-image: -webkit-linear-gradient(top,#f8dbae,#edbd71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 5.333vw;
    text-align: center;
    margin-top: 3vw
}

.sm .right {
    flex: 1;
    padding-left: 1.333vw
}

.sm .right p {
    color: #dadada;
    font-size: 3vw;
    line-height: 6.4vw
}

.sm>span {
    line-height: 5.067vw
}

.sm .left span,.sm>span {
    color: #ddb285;
    font-size: 3vw;
    padding-top: 1.333vw;
    text-align: center
}

.sm .left span {
    width: 100%;
    display: block;
    line-height: 1.4
}

.sm .left :before {
    content: "";
    position: absolute;
    width: .02rem;
    height: 100%;
    background: #f6d5a1;
    right: 0;
    top: 0
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ddb285;
	font-size: 35px;
	width: 33vw;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.home-container{
position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #282828;
    overflow-y: auto;
    padding-bottom: 20%;
	height: 100%;
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  /* width: calc(100% - 50px); */
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  margin-top: -10%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 130px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #979799;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #979799;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background-color: #282828;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width:335px;
  height:290px;
}
.home-scroll{
  /* padding-bottom: 100px; */
}
.movie_list_1 .movie-list-item{
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;

}
.movie_list_1 .movie-list-item:nth-child(odd){
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right:110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}
</style>
