<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="在线客服" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#ddb285" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="ifrmae_page" style="height: 100vh; background-color: #f7f7f7;">
      <iframe width="100%" height="100%" frameborder="0"  style="margin-top: -13%;
    height: 93vh;"  :src="this.kefu_url"></iframe>
	<!-- <div style="width: 100%; height: 30px;"></div> -->
    </div>
	
  </div>
</template>

<script>
export default {
  data() {
    return {
		chnal:null,
		kefu_url:'',
    };
  },
  methods: {
    back(){
      this.$router.push({ path: '/Mine' });
    },
	isups(e){
		this.$http({
			method: 'post',
			data:{visiter_id:e},
			url: 'kefu_up'
			}).then()
	}
  },
  created() {
	this.$http({
		method: 'get',
		url: 'user_info'
		}).then(res=>{
		if(res.code === 200){
		
		this.youchl = res.data.username;
		this.isups(res.data.username);
		
			this.$http({
			method: 'post',//查询用户频道
			data:{username:this.youchl},
			url: 'kefu_info'
			}).then(res=>{
			if(res.code === 200){
				this.user_channel = res.data.visiter_id;
				
				this.kefu_url = 'https://'+ this.$kefu_urls +'/index/index/home?visiter_id='+ this.user_channel +'&visiter_name=&avatar=&business_id=1&groupid=0&special=1&service_id=1'
				console.log(this.user_channel);
			}else {
				//this.$toast(res.msg);
			}
			}
				
			)	
			
		}else if(res.code ===401){
		this.$toast(res.msg);
		}
	})
	
	
  },
  mounted(){
    /**
     * iframe-宽高自适应显示
     */
   // const oIframe = document.getElementById('iframe_web');
   //  const deviceHeight = document.documentElement.clientHeight;
   // oIframe.style.height = (Number(deviceHeight)-65) + 'px'; //数字是页面布局高度差
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
</style>
